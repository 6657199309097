<template>
  <v-app>
    <PrivateLayout v-if="authenticated" />
    <PublicLayout v-else />

    <NotificationSnackbar />
  </v-app>
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/firebase/config";
import lazyloadComponent from "@/utils/lazyloadComponent";

export default {
  name: "App",
  components: {
    // layouts
    PublicLayout: lazyloadComponent("layouts/Public/Container"),
    PrivateLayout: lazyloadComponent("layouts/Private/Container"),
    // components
    NotificationSnackbar: lazyloadComponent("components/NotificationSnackbar"),
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const authenticated = computed(() => store.getters["auth/authenticated"]);
    const { mobile } = useDisplay();

    onMounted(() => {
      onAuthStateChanged(auth, (user) => {
        console.log(user);
        if (user?.accessToken) {
          store.commit("auth/updateUser", user);
          store.commit("auth/setAuth", true);
          router.push({ name: "Dashboard" });
        }
      });
    });
    return { authenticated, mobile };
  },
};
</script>

<style lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none !important;
  width: 0px !important;
  background: transparent !important;
}

body {
  /* Hide scrollbar for IE, Edge add Firefox */
  -ms-overflow-style: none !important;
  scrollbar-width: none !important; /* Firefox */
}
</style>
