function defaultState() {
  return {
    data: null,
    dataFetched: false,
    totalValue: 0,
  };
}

const state = defaultState();

const getters = {
  data: (state) => state.data,
  dataFetched: (state) => state.dataFetched,
  totalValue: (state) => state.totalValue,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  setData(state, payload) {
    state.data = payload;
  },
  setDataFetched(state, payload) {
    state.dataFetched = payload;
  },
  setTotalValue(state, payload) {
    state.totalValue = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit("reset");
  },
  setData({ commit }, payload) {
    commit("setData", payload);
  },
  setDataFetched({ commit }, payload) {
    commit("setDataFetched", payload);
  },
  setTotalValue({ commit }, payload) {
    commit("setTotalValue", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
