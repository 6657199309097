import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "@/firebase/config";

function defaultState() {
  return {
    email: "",
  };
}

const state = defaultState();

const getters = {
  email: (state) => state.email,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  setEmail(state, payload) {
    state.email = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit("reset");
  },
  async requestCode({ commit }, email) {
    try {
      await sendPasswordResetEmail(auth, email);
      commit("setEmail", email);
    } catch (error) {
      // // UserNotFoundException
      // throw new Error(i18n.t(`resetPassword.error.${error.code}`));
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
