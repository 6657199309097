import { createApp } from "vue";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
// Plugins
import { registerPlugins } from "@/plugins";

import firebaseConfig from "@/firebaseConfig";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
getAnalytics(firebaseApp);

const app = createApp(App).use(router).use(store);

registerPlugins(app);

app.mount("#app");
