import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import publicRoutes from "@/router/public";
import privateRoutes from "@/router/private";

const routes = publicRoutes.concat(privateRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authenticated = store.getters["auth/authenticated"];
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyLoggedOut = to.matched.some((record) => record.meta.onlyLoggedOut);

  if (!isPublic && !authenticated) {
    return next({
      name: "Login",
      query: { redirect: to.fullPath },
    });
  }

  if (authenticated && onlyLoggedOut) {
    return next("/");
  }

  return next();
});

export default router;
