import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { auth } from "@/firebase/config";

function defaultState() {
  return {
    user: {},
    authenticated: false,
  };
}

const state = defaultState();

const getters = {
  user: (state) => state.user,
  authenticated: (state) => state.authenticated,
  uid: (state) => state.user.uid,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  setAuth(state, payload) {
    state.authenticated = payload;
  },
  updateUser(state, payload) {
    state.user = {
      ...state.user,
      ...payload,
    };
  },
};

const actions = {
  reset({ commit }) {
    commit("reset");
  },
  async signin({ commit }, data) {
    try {
      console.log(data);
      const { email, password } = data;
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      commit("updateUser", user);
      commit("setAuth", true);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async signinWithGoogle({ commit }) {
    try {
      const provider = new GoogleAuthProvider();
      const { user } = await signInWithPopup(auth, provider);
      console.log("signInWithPopup", user);

      commit("updateUser", user);
      commit("setAuth", true);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async signout({ dispatch }) {
    try {
      // TODO: try global signOut
      await signOut(auth);
      dispatch("application/resetStore", null, { root: true });
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
