export default {
  required: "Field is required",
  generic: {
    required: "Field is required",
    alphanum: "Field must be alphanumeric",
    numbers: "Only numbers are allowed",
  },
  email: {
    required: "E-mail is required",
    valid: "E-mail must be valid",
  },
  password: {
    required: "Password is required",
    length: "Password must have more than 8 characters",
    uppercase: "Must have at least one uppercase character",
    lowercase: "Must have at least one lowercase character",
    number: "Must have one number",
    specialcharacter: "Must have one special character [!{'@'}#$%]",
  },
  emailHint: "Please provide a valid email address",
  passwordHint: "Minimum 6 characters, uppercase letters and numbers",
};
