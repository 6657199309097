export default {
  login: {
    title: "Login",
    desc: "Sign in with your email address and your password",
    login: "Login",
    signinWithGoogle: "Sign in with Google",
    register: "Create an AhabLabs Account",
    email: "Email",
    password: "Password",
    confirmPassword: "Password",
    resetPassword: "Forgot Password?",
    validators: {
      email: {
        required: "E-mail is required",
        valid: "E-mail must be valid",
      },
      password: {
        required: "Password is required",
        length: "Password must have more than 8 characters",
        uppercase: "Must have at least one uppercase character",
        lowercase: "Must have at least one lowercase character",
        number: "Must have one number",
        specialcharacter: "Must have one special character [!@#$%]",
      },
    },
    emailHint: "Please provide a valid email address",
    passwordHint: "Minimum 6 characters, uppercase letters and numbers",
  },
  registration: {
    buttons: {
      next: "Next",
      confirm: "Confirm",
      resend: "Resend Code",
    },
    create: {
      title: "Create Account",
      desc: "Please provide you email address and choose a safe password",
      fields: {
        email: "Email",
        password: "Password",
      },
      hints: {
        email: "You'll have to verify your email address",
      },
    },
    confirm: {
      title: "Confirm Your Registration",
      desc: "We sent you an email with a 6-digit verification code. Please enter it below.",
    },
    finish: {
      title: "Congrats - Successfull Registration",
      desc: "Let's GOOOOOOO!!!",
    },
  },
  resetPassword: {
    title: "Reset Password",
    desc: "Reset your password",
    email: "Email",
    validators: {
      email: {
        required: "E-mail is required",
        valid: "E-mail must be valid",
      },
    },
    emailHint: "Please provide a valid email address",
    button: "Reset",
  },
};
