import { createStore } from "vuex";

import application from "@/store/modules/application";
import auth from "@/store/modules/auth";
import registration from "@/store/modules/registration";
import resetPassword from "@/store/modules/resetPassword";
import strategy from "@/store/modules/strategy";
import profile from "@/store/modules/profile";
import notification from "@/store/modules/notification";

export default createStore({
  modules: {
    application,
    auth,
    registration,
    resetPassword,
    strategy,
    profile,
    notification,
  },
});
