import {
  createUserWithEmailAndPassword,
  // sendEmailVerification,
} from "firebase/auth";
import { auth } from "@/firebase/config";

function defaultState() {
  return {
    navigation: {
      steps: ["CreateView", "FinishView"],
      index: 0,
    },
    email: "",
  };
}

const state = defaultState();

const getters = {
  steps: (state) => state.navigation.steps,
  index: (state) => state.navigation.index,
  email: (state) => state.email,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  nextStep(state) {
    if (state.navigation.index !== state.navigation.steps.length)
      state.navigation.index += 1;
  },
  setEmail(state, payload) {
    state.email = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit("reset");
  },
  nextStep({ commit }) {
    commit("nextStep");
  },
  async create({ commit }, data) {
    try {
      const { email, password } = data;
      console.log(data);
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log(user);
      // // Email Verification
      // await sendEmailVerification(user);
      // const user = auth.currentUser;
      // const actionCodeSettings = {
      //   url: `${process.env.VUE_APP_HOST_NAME}/login/?email=${user.email}`,
      // };
      // await sendEmailVerification(user, actionCodeSettings);
      commit("setEmail", email);
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
