import lazyloadRoute from "@/utils/lazyloadRoute";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: lazyloadRoute("views/Login/Index"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: lazyloadRoute("views/Registration/Index"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: lazyloadRoute("views/ResetPassword/Index"),
  },
];

export default routes.map((route) => {
  const meta = {
    public: true,
    onlyLoggedOut: true,
  };
  return { ...route, meta };
});
