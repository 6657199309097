import { en } from "vuetify/lib/locale";
import validators from "@/locales/en/validators";
import auth from "@/locales/en/auth";

export default {
  ...en,
  ...auth,
  validators,
  marketplace: {
    welcome: "Welcome to the AhabLabs🤗",
    category: {
      trending: "Trending NFTs",
    },
  },
  listNFT: {
    form: {
      metadata: {
        name: "NFT Name",
        description: "NFT Description",
        price: "Price (in ETH)",
      },
      file: "Upload Image",
    },
    btn: "List NFT",
    setFile: {
      notification: {
        success: "Image has been uploaded to IPFS!",
        error: (e) => `Upload error: ${e.named("e")}`,
      },
    },
    uploadMetadatToIPFS: {
      notification: {
        info: "Metadata has been uploaded to IPFS!",
        error: (e) => `Upload error: ${e.named("e")}`,
      },
    },
    listNFT: {
      notification: {
        pending:
          "Listing the NFT... Please Wait for the transaction to be confirmed (~5min)",
        success: "You successfully listed the NFT!",
        error: (e) => `Transaction error: ${e.named("e")}`,
      },
    },
  },
  profile: {
    myNftNumber: "Number of NFTs",
    totalValue: "Total Value",
    myNfts: "My NFTs",
    noNfts: "You don't own any NFTs yet... Go buy some at the marketplace!",
  },
  nftDetails: {
    data: {
      name: "Name: ",
      description: "Description: ",
      price: "Price: ",
      owner: "Owner: ",
      seller: "Seller: ",
    },
    btn: "Buy NFT",
    chip: "You are the Proud Owner of this NFT 💪",
    buyNFT: {
      notification: {
        pending:
          "Buying the NFT... Please Wait for the transaction to be confirmed (~5min)",
        success: "You successfully bought the NFT!",
        error: (e) => `Transaction error: ${e.named("e")}`,
      },
    },
  },
  views: {
    Dashboard: "Dashboard",
    Strategies: "Strategies",
    Profile: "Profile",
  },
  appBar: {
    connection: (address) => `Connected with ${address.named("address")}`,
    walletButton: {
      connected: "Connected",
      disconnected: "Connect Wallet",
    },
    notification: {
      incorrectNetwork:
        "Incorrect network! Switch your metamask network to Goerli!",
    },
    metamask: {
      connect: "Connection to app established 💪",
      disconnect: "Sad to see you disconnected 😢",
      message: (msg) => `Some message: ${msg.named("msg")}`,
    },
  },
  sideMenu: {
    title: "AhabLabs Navigation",
  },
  snackbar: {
    close: "close",
  },
};
