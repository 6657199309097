function defaultState() {
  return {
    notification: {
      colors: {
        error: "red",
        success: "green",
        info: "blue",
      },
      types: ["error", "success", "info"],
      current: null,
    },
    pageTitle: "",
  };
}

const state = defaultState();

const getters = {
  notification: (state) => state.notification.current,
  colors: (state) => state.notification.colors,
  pageTitle: (state) => state.pageTitle,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  notify(state, payload) {
    state.notification.current = payload;
    setTimeout(() => {
      state.notification.current = null;
    }, 3000);
  },
  setPageTitle(state, pageTitle) {
    state.pageTitle = pageTitle;
  },
};

const actions = {
  reset({ commit }) {
    commit("reset");
  },
  notify({ commit }, payload) {
    commit("notify", payload);
  },
  setPageTitle({ commit }, pageTitle) {
    commit("setPageTitle", pageTitle);
  },
  // reset all modules
  resetStore({ dispatch }) {
    const modules = Object.keys(this._modules.root._children);
    modules.forEach((module) => {
      dispatch(`${module}/reset`, null, { root: true });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
