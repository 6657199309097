// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#24292f",
          background: "#f9e8d0",
          error: "#b71c1c",
          danger: "#d63031",
          info: "#0984e3",
          secondary: "#f9e8d0",
          success: "#00cec9",
          surface: "#181a1d",
          warning: "#2d3436",
        },
        dark: false,
        variables: {},
      },
      dark: {
        colors: {
          primary: "#24292f",
          background: "#343541",
          error: "#b71c1c",
          danger: "#d63031",
          info: "#0984e3",
          secondary: "#343541",
          success: "#00cec9",
          surface: "#181a1d",
          warning: "#2d3436",
        },
        dark: true,
        variables: {},
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
