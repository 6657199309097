function defaultState() {
  return {
    notification: null,
  };
}

const state = defaultState();

const getters = {
  notification: (state) => state.notification,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  notify(state, payload) {
    state.notification = payload;
    setTimeout(() => {
      this.commit("notification/reset");
    }, 5000);
  },
};

const actions = {
  reset({ commit }) {
    commit("reset");
  },
  error({ commit }, message) {
    const payload = {
      type: "error",
      message,
    };
    commit("notify", payload);
  },
  success({ commit }, message) {
    const payload = {
      type: "success",
      message,
    };
    commit("notify", payload);
  },
  info({ commit }, message) {
    const payload = {
      type: "info",
      message,
    };
    commit("notify", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
