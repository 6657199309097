import lazyloadRoute from "@/utils/lazyloadRoute";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "Dashboard",
    component: lazyloadRoute("views/DashboardView"),
  },
  {
    path: "/strategies",
    name: "Strategies",
    component: lazyloadRoute("views/Strategies/Index"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: lazyloadRoute("views/ProfileView"),
  },
  {
    path: "/strategies/:strategyId",
    name: "strategyDetails",
    component: lazyloadRoute("views/Strategies/StrategyDetailsView"),
    props: true,
  },
];

export default routes.map((route) => {
  const meta = {
    public: false,
  };
  return { ...route, meta };
});
